// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-content-capture-questionnaire-js": () => import("./../../../src/pages/content-capture-questionnaire.js" /* webpackChunkName: "component---src-pages-content-capture-questionnaire-js" */),
  "component---src-pages-development-process-js": () => import("./../../../src/pages/development-process.js" /* webpackChunkName: "component---src-pages-development-process-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-hostingdown-js": () => import("./../../../src/pages/hostingdown.js" /* webpackChunkName: "component---src-pages-hostingdown-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-software-development-js": () => import("./../../../src/pages/service/software-development.js" /* webpackChunkName: "component---src-pages-service-software-development-js" */),
  "component---src-pages-website-minimum-delivery-scope-js": () => import("./../../../src/pages/website-minimum-delivery-scope.js" /* webpackChunkName: "component---src-pages-website-minimum-delivery-scope-js" */),
  "component---src-pages-website-pre-release-check-list-js": () => import("./../../../src/pages/website-pre-release-check-list.js" /* webpackChunkName: "component---src-pages-website-pre-release-check-list-js" */),
  "component---src-pages-website-support-scope-js": () => import("./../../../src/pages/website-support-scope.js" /* webpackChunkName: "component---src-pages-website-support-scope-js" */),
  "component---src-pages-why-online-presence-js": () => import("./../../../src/pages/why-online-presence.js" /* webpackChunkName: "component---src-pages-why-online-presence-js" */),
  "component---src-pages-wordpress-js": () => import("./../../../src/pages/wordpress.js" /* webpackChunkName: "component---src-pages-wordpress-js" */),
  "component---src-templates-projects-index-page-js": () => import("./../../../src/templates/ProjectsIndexPage.js" /* webpackChunkName: "component---src-templates-projects-index-page-js" */)
}

